import HttpFactory from './factory';
import type {ApiCollectionResponse} from '~/models/Response/ApiCollectionResponse';
import type {Category} from '~/models/Category';

class CategoryRepository extends HttpFactory {
    private RESOURCE = '/categories';

    async index(params?: object): Promise<ApiCollectionResponse<Category>> {
        return await this.getJson(`${this.RESOURCE}`, params);
    }

    async fetchCategories(): Promise {
        return await this.getJson(`${this.RESOURCE}?perPage=99999`);
    }
}

export default CategoryRepository;