import HttpFactory from './factory';
import type {Setting} from '~/models/Setting';
import type {ApiResourceResponse} from '~/models/Response/ApiResourceResponse';

class SettingsRepository extends HttpFactory {
    private RESOURCE = '/settings';

    async index(params?: object): Promise<ApiResourceResponse<Setting>> {
        return await this.getJson(`${this.RESOURCE}`, params);
    }
}

export default SettingsRepository;