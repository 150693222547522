import HttpFactory from '~/repository/factory';

class RssRepository extends HttpFactory {
    private RESOURCE = '/rss';

    async index(): Promise {
        return await this.getJson(`${this.RESOURCE}`);
    }

    async blog(): Promise {
        return await this.getJson(`${this.RESOURCE}/blog`);
    }

    async opinions(): Promise {
        return await this.getJson(`${this.RESOURCE}/opinions`);
    }

    async categories(url: string): Promise {
        return await this.getJson(`${this.RESOURCE}/${url}`);
    }

    async lists(slug: string): Promise {
        return await this.getJson(`${this.RESOURCE}/lists/${slug}`);
    }

    async createNotificationsXML(): Promise {
        return await this.getJson(`${this.RESOURCE}/create_notifications_xml`);
    }

}

export default RssRepository;