import HttpFactory from './factory';
import type {ApiCollectionResponse} from '~/models/Response/ApiCollectionResponse';
import type {Post} from '~/models/Post';
import type {ApiResourceResponse} from '~/models/Response/ApiResourceResponse';

class PostsRepository extends HttpFactory {
    private RESOURCE = '/posts';

    async index(params?: object): Promise<ApiCollectionResponse<Post>> {
        return await this.getJson(`${this.RESOURCE}`, params);
    }

    async show(slug?: string): Promise<ApiResourceResponse<Post>> {
        return await this.getJson(`${this.RESOURCE}/${slug}`);
    }

    async related(slug: string, id: string, params?: object): Promise<ApiCollectionResponse<Post>> {
        return await this.getJson(`${this.RESOURCE}/${slug}/${id}/related`, params);
    }

    async categoryPosts(params?:object):Promise<ApiResourceResponse<Post>> {
        return await this.getJson(`${this.RESOURCE}`, params);
    }
}

export default PostsRepository;