import {find, isEmpty} from 'lodash-es';
import type {ApiCollectionResponse} from '~/models/Response/ApiCollectionResponse';
import type {Category} from '~/models/Category';
import * as Sentry from '@sentry/nuxt';

export default defineNuxtRouteMiddleware(async (to, _from) => {

    try {

        const response = await $fetch<ApiCollectionResponse<Category>>('/api/fetchCategories');

        const routeCategory = to.params.category;
        const routeSubcategory = isArray(to.params.sub_category) ? to.params.sub_category.join('/') : to.params.sub_category;

        let actualUrlPrefix = `/${routeCategory}`;

        if (!isEmpty(routeSubcategory)) {
            actualUrlPrefix = `${actualUrlPrefix}/${routeSubcategory}`;
        }

        const allCategories = response.data;

        allCategories.push({
            url: '/news/all',
        });

        const categoryForUrl = find(response.data, function (category: Category) {
            return category.url === actualUrlPrefix || `/archive${category.url}` === actualUrlPrefix;
        });

        if (isEmpty(categoryForUrl)) {
            return showError(createError({
                statusCode: 404,
                statusMessage: 'Page Not Found',
                message: `The page "${to.path}" was not found`,
            }));
        }

    } catch (error) {
        Sentry.captureException(error);
        return showError(createError({statusCode: 404, statusMessage: 'Something went wrong. Please try again later'}));
    }
});